
.startButton {
    border: solid .3rem white;
    border-radius: 1rem;
    align-self: center;
    padding: 1rem 2rem 1rem 2rem;
    color: white;
    font-weight: bold;
    font-size: 5rem;
    margin-top: 3rem;
    background-color:rgb(63, 63, 63);
    transition: .3s ease-out;
    cursor:pointer;
}

.startButton:hover {
    background-color:rgb(52, 169, 173);
    scale: 1.2;
}