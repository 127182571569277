
.soundSelectorDiv {
    margin-bottom: 2rem;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .8rem 1rem;
    border: solid .1rem white;
    border-radius: .6rem;
}

.soundSelectorTitle {
    margin-right: 1rem;
    color: white;
}

.soundSelector {
    border-radius: .5rem;
    background-color: rgb(54, 54, 54);
    color: white;
    padding: .4rem;
}