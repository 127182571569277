
.button {
    border: solid white 2px;
    border-radius: 8px;
    height: 4rem;
    margin: .2rem;
    align-self: stretch;
    flex: 1;
    display: flex;
    overflow:hidden;
    transition: 0.2s;
}

.innerButtonFlash {
    transition: 0s;
    background-color: white;
    flex: 1;
}

.innerButton {
    transition: 0.4s ease-out;
    flex: 1;
}