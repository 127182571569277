
.App {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: min(100%, 30rem);
  margin: auto;
}

h1 {
  color: white;
  align-self: center;
}